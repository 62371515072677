// 引用两个插件
import html2canvas from 'html2canvas' // 转为图片
import printJS from 'print-js' // 打印
import disableScroll from 'disable-scroll'

export default {
	name: 'WarrantSearch',
	created () {
		// this.$$.navbarAnimation()
		this.$nextTick(function () {
			// 监听屏幕
			this.MonitorScreen()
			// 搜索框显示
			for (let i = 0, max = this.selectData; i < max.length; i++) {
				this.ariseCombobox(i, this.selectData[i].data[0].name)
			}
			// 获得搜索框数据
			this.getSelectorSearchData()
		})
	},
	mounted () {
		this.$nextTick(function () { })
	},
	data () {
		return {
			selectData: [{
				name: this.$t('WSe.AU'),
				data: [{
					id: 0,
					name: this.$t('WSe.AU/CA'),
					value: 'all',
					visible: false,
					show: true
				}, {
					id: 1,
					name: this.$t('WSe.CA'),
					value: '',
					visible: false,
					show: false
				}, {
					id: 2,
					name: this.$t('WSe.AU'),
					value: 'all',
					visible: false,
					show: false
				}]
			},
			{
				name: this.$t('WSe.C/P'),
				data: [{
					name: `${this.$t('WSe.Ca')}/${this.$t('WSe.Pu')}`,
					value: 'all'
				},
				{
					name: this.$t('WSe.Ca'),
					value: 'call'
				},
				{
					name: this.$t('WSe.Pu'),
					value: 'put'
				}
				]
			},
			{
				name: this.$t('WSe.Am'),
				data: [{
					name: this.$t('WSe.Am'),
					value: 'all'
				},
				{
					name: this.$t('WSe.Atm'),
					value: 'atm'
				},
				{
					name: this.$t('WSe.Itm'),
					value: 'itm'
				},
				{
					name: this.$t('WSe.Ootm'),
					value: 'otm'
				}
				]
			},
			{
				name: this.$t('WSe.Amy'),
				data: null,
				data: [{
					name: this.$t('WSe.Amy'),
					value: 'all'
				},
				{
					name: '< 1 ' + this.$t('WSe.mo'),
					value: 'lt1'
				},
				{
					name: '1-3 ' + this.$t('WSe.mos'),
					value: 'btw1t3'
				},
				{
					name: '3-6 ' + this.$t('WSe.mos'),
					value: 'btw3t6'
				},
				{
					name: '6-9 ' + this.$t('WSe.mos'),
					value: 'btw6t9'
				},
				{
					name: '> 9 ' + this.$t('WSe.mos'),
					value: 'mt9'
				}
				]
			},
			{
				name: this.$t('WSe.Aeg'),
				data: [{
					name: this.$t('WSe.Aeg'),
					value: 'all'
				},
				{
					name: '< 5x',
					value: 'ls5'
				},
				{
					name: '5-10x',
					value: 'btw5t10'
				},
				{
					name: '10-15x',
					value: 'btw10t15'
				},
				{
					name: '15x',
					value: 'mt15'
				}
				]
			},
			{
				name: this.$t('WSe.AI'),
				data: [{
					name: this.$t('WSe.AI'),
					value: 'all',
					visible: false,
					id: 0
				}]
			},
			{
				name: this.$t('WSe.Ae'),
				data: [{
					name: this.$t('WSe.Ae'),
					value: 'all'
				}]
			},
			{
				name: this.$t('WSe.Ai'),
				data: [{
					name: this.$t('WSe.Ai'),
					value: 'all'
				},
				{
					name: this.$t('WSe.Tw'),
					value: 'HOTLIST'
				},
				{
					name: this.$t('WSe.Hl'),
					value: 'HIGHLQDY'
				},
				{
					name: this.$t('WSe.Ne'),
					value: 'APPCHEXPRY'
				},
				{
					name: this.$t('WSe.Is'),
					value: 'SOLDOUT'
				},
				{
					name: this.$t('WSe.Nw'),
					value: 'NEWWARRANT'
				},
				{
					name: this.$t('WSe.Mr'),
					value: 'MORERESPONSIVE'
				},
				{
					name: this.$t('WSe.Heg'),
					value: 'HIGHEFFECTIVEGEARING'
				},
				{
					name: this.$t('WSe.Ltd'),
					value: 'LOWTIMEDECAY'
				}
				]
			},
			{
				name: this.$t('int.Se'),
				data: [{
					name: this.$t('WSe.As'),
					value: 'all'
				},
				{
					name: '< 5x',
					value: 'ls5'
				},
				{
					name: '5-10x',
					value: 'btw5t10'
				},
				{
					name: '10-15x',
					value: 'btw10t15'
				},
				{
					name: '15x',
					value: 'mt15'
				}
				]
			}
			],
			// 列表头部数据
			tableHeadData: [{
				namef: 'Warrant Name',
				name: this.$t('WSe.WN'),
				sort: 'wcode',
				visible: true
			},
			{

				namef: 'Indicators',
				name: this.$t('WSe.In'),
				sort: '',
				visible: true
			},
			{
				namef: 'Warrant Issuer',
				name: this.$t('WSe.WI'),
				sort: 'isr',
				visible: true
			},
			{
				namef: 'Time to Maturity',
				name: this.$t('WSe.TtM'),
				sort: 'mdate',
				visible: false
			},
			{
				namef: 'Expiry Date',
				name: this.$t('WSe.ED'),
				sort: 'mdate',
				visible: true
			},
			{
				namef: 'Type of Warrant',
				name: this.$t('WSe.ToW'),
				sort: 'type',
				visible: true
			},
			{
				namef: 'Exercise Price',
				name: this.$t('WSe.EP'),
				sort: 'ep',
				visible: true
			},
			{
				namef: 'Exercise Ratio',
				name: this.$t('WSe.ER'),
				sort: 'dwps',
				visible: true
			},
			{
				namef: 'Traded Volume (\'000)',
				name: `${this.$t('WSe.TV')} ('000)`,
				sort: 'tv',
				visible: true
			},
			{
				namef: 'Bid Price (RM)',
				name: this.$t('hp.BP') + ' (' + this.$t('hp.RM') + ')',
				sort: 'bp',
				visible: true
			},
			{
				namef: "Bid Volume ('000)",
				name: this.$t('WSe.BV') + " ('000)",
				sort: 'bv',
				visible: true
			},
			{
				namef: 'Bid Change (%)',
				name: this.$t('WSe.BC') + ' (%)',
				sort: 'pc',
				visible: true
			},
			{
				namef: 'Ask Price (RM)',
				name: this.$t('WSe.AP') + ' (' + this.$t('hp.RM') + ')',
				sort: 'ap',
				visible: true
			},
			{
				namef: "Ask Volume ('000)",
				name: this.$t('WSe.AV') + " ('000)",
				sort: 'av',
				visible: true
			},
			{
				namef: 'Effective Gearing (x)',
				name: this.$t('int.EG') + ' (x)',
				sort: 'eg',
				visible: true,
				top: 66,
				left: 250
			},
			{
				namef: 'Sensitivity (Ticks)',
				name: this.$t('int.Se') + ' (' + this.$t('WSe.Ti') + ')',
				sort: 'sen',
				visible: true,
				top: 66,
				left: 250
			},
			{
				namef: 'Implied Volatility (%)',
				name: this.$t('int.IV') + ' (%)',
				sort: 'iv',
				visible: true,
				top: 84,
				left: 250
			},
			{
				namef: 'Premium (%)',
				name: this.$t('int.Pr') + ' (%)',
				sort: 'pre',
				visible: false,
				top: 84,
				left: 250
			},
			{
				namef: 'Delta (%)',
				name: this.$t('int.De') + ' (%)',
				sort: 'dlt',
				visible: false,
				top: 48,
				left: 250
			},
			{
				namef: 'Theta (days)',
				name: this.$t('int.Th') + ' (' + this.$t('WSe.da') + ')',
				sort: 'tt',
				visible: false,
				top: 48,
				left: 250
			},
			{
				namef: 'Moneyness',
				name: this.$t('WSe.Mo'),
				sort: 'mn',
				visible: true
			}
			],
			conditionData: [{
				icon: require('../../../../../public/image/Daily_Highlight.png'),
				name: this.$t('int.DH'),
				hint: '<strong>' + this.$t('int.DH') + ':</strong> ' + this.$t('int.Stcitm')
			},
			{
				icon: require('../../../../../public/image/More_Responsive.png'),
				name: this.$t('int.MR'),
				hint: '<strong>' + this.$t('int.MR') + ': </strong> ' + this.$t('int.MSsmou') + '.'
			},
			{
				icon: require('../../../../../public/image/Low_Time_Decay.png'),
				name: this.$t('int.LTD'),
				hint: '<strong>' + this.$t('int.LTD') + ':</strong> ' + this.$t('int.MSmtd') + '.'
			},
			{
				icon: require('../../../../../public/image/Low_Inventory.png'),
				name: this.$t('int.LI'),
				hint: '<strong>' + this.$t('int.LI') + ':</strong> ' + this.$t('int.MScMa') + '.'
			},
			{
				icon: require('../../../../../public/image/Highest_Liquidity.png'),
				name: this.$t('int.HL'),
				hint: '<strong>' + this.$t('int.HL') + ':</strong> ' + this.$t('int.MSuGt') + '.'
			},
			{
				icon: require('../../../../../public/image/High_Effective_Gearing.png'),
				name: this.$t('int.HEG'),
				hint: '<strong>' + this.$t('int.HEG') + ':</strong> ' + this.$t('int.MShSu') + '.'
			}
			],
			// 列表内容数据
			tableContentData: [],
			publishTime: '',
			underlyingData: ['all'],
			underlyingDackupsData: [],
			issuerData: ['MSI'],
			sortBy: 'wcode',
			sortOrder: 'asc',
			typeD: 'all',
			maturityD: 'all',
			moneynessD: 'all',
			effectiveGearingD: 'all',
			expiryD: 'all',
			sensitivityD: 'all',
			indicatorD: 'all',
			// underlying的input text
			underlyingInputText: '',
			logUnderlyingInputText: '',
			// underlying搜索用的数据
			underlyingSeekData: [],
			// issuer的input text
			ssuerInputText: '',
			logSsuerInputText: '',
			// WARRANT的长度
			WARRANTLength: 8,
			MARKETLength: 5,
			INDICATORSLength: 4,
			iosWARRANT: true,
			iosMARKET: true,
			iosINDICATORSF: true,
			// 点击显示数据的数目
			dataLiNum: 14,
			// 全选打勾
			allData: false,
			// 禁止点击
			forbid: false,
			// 下拉菜单的显示
			ifUnderlying: false,
			ifMaybank: false,

			iftableHeadShow: false,
			// 打印去掉箭头
			printif: true,
			scrollBarScrollLeft: 0,
			Results: 0,
			nameFixationTableBoxT: false
		}
	},
	computed: {

	},
	watch: {

	},
	methods: {
		// 获得搜索框数据
		getSelectorSearchData: function () {
			const _this = this
			$.ajax({
				type: 'POST',
				dataType: 'json',
				url: this.$$.mibb + 'GetScreenerData',
				data: { token: 'webkey', init: '1' },
				success: res => {
					if (res.status == 'SUCCESS') {
						// underlying下拉菜单的数据
						$.each(res.underlyings, function (iu, cu) {
							_this.selectData[0].data.push({
								name: `(${cu.underlying_ticker}) ${cu.underlying_name}`,
								value: cu.underlying_ticker,
								visible: false,
								id: iu + 3,
								show: true
							})
						})
						this.underlyingSeekData = this.selectData[0].data
						// issuers下拉菜单的数据
						$.each(res.issuers, function (is, cs) {
							_this.selectData[5].data.push({
								name: cs == 'MACQ' ? 'MACQUARIE' : cs.toUpperCase() == 'MSI' ? 'MAYBANK' : cs.toUpperCase(),
								value: cs,
								visible: false,
								id: is + 1
							})
						})
						// expiry 下拉菜单的数据
						$.each(res.expiryDates, function (ie, ce) {
							_this.selectData[6].data.push({
								name: ce,
								value: ce
							})
						})
						// 获取表格数据
						this.GetScreenerData()
						this.$$.tableWidth($('#WarrantSearch').find('.scrollBar').find('div'), $('#WarrantSearch').find('.tableBox').find('.table'), true)
					}
				},
				error: function (XMLHttpRequest) { }
			})
		},
		// 获取表格数据
		GetScreenerData: function () {
			this.tableContentData = []
			const _this = this
			let Textis
			$.ajax({
				type: 'POST',
				dataType: 'json',
				url: this.$$.mibb + 'GetScreenerData',
				data: {
					token: 'webkey',
					underlying: this.underlyingData.toString(),
					type: this.typeD,
					issuer: this.issuerData.toString().replace(/,/g, ';'),
					maturity: this.maturityD,
					moneyness: this.moneynessD,
					effectiveGearing: this.effectiveGearingD,
					expiry: this.expiryD,
					sensitivity: this.sensitivityD,
					indicator: this.indicatorD,
					sortBy: this.sortBy,
					sortOrder: this.sortOrder
				},
				success: res => {
					if (res.status == 'SUCCESS') {
						// 菜单input显示text
						if (this.underlyingData[0] == 'all') {
							this.underlyingInputText = this.$t('WSe.AU')
							// this.underlyingData = []
						}
						if (this.issuerData[0] === 'MSI' && this.issuerData.length === 1) {
							$.each(this.selectData[5].data, function (i, c) {
								if (c.name == 'MAYBANK') {
									c.visible = true
								}
							})
						}
						Textis = JSON.parse(JSON.stringify(this.issuerData))
						$.each(Textis, (i, c) => {
							if (c == 'MSI') {
								Textis[i] = 'MAYBANK'
							}
						})
						this.ssuerInputText = Textis.filter(tiem => tiem !== '').toString().toUpperCase()
						this.tableContentData = res.data
						console.log(res.data);

						$.each(this.tableContentData, (i, c) => {
							const conditionIcon = [{
								icon: require('../../../../../public/image/Daily_Highlight.png'),
								visible: 0,
								name: '<strong>' + this.$t('int.DH') + ':</strong> ' + this.$t('int.Stcitm')
							},
							{
								icon: require('../../../../../public/image/More_Responsive.png'),
								visible: 0,
								name: '<strong>' + this.$t('int.MR') + ': </strong> ' + this.$t('int.MSsmou') + '.'
							},
							{
								icon: require('../../../../../public/image/Low_Time_Decay.png'),
								visible: 0,
								name: '<strong>' + this.$t('int.LTD') + ':</strong> ' + this.$t('int.MSmtd') + '.'
							},
							{
								icon: require('../../../../../public/image/Low_Inventory.png'),
								visible: 0,
								name: '<strong>' + this.$t('int.LI') + ':</strong> ' + this.$t('int.MScMa') + '.'
							},
							{
								icon: require('../../../../../public/image/Highest_Liquidity.png'),
								visible: 0,
								name: '<strong>' + this.$t('int.HL') + ':</strong> ' + this.$t('int.MSuGt') + '.'
							},
							{
								icon: require('../../../../../public/image/High_Effective_Gearing.png'),
								visible: 0,
								name: '<strong>' + this.$t('int.HEG') + ':</strong> ' + this.$t('int.MShSu') + '.'
							}
							]
							conditionIcon[0].visible = c.daily_highlights
							conditionIcon[1].visible = c.more_responsive
							conditionIcon[2].visible = c.low_time_decay
							conditionIcon[3].visible = c.soldout
							conditionIcon[4].visible = c.highest_liquidity
							conditionIcon[5].visible = c.high_effective_gearing
							c.conditionIcon = conditionIcon
						})
						this.$forceUpdate()
						this.publishTime = this.TurnTime(res.last_update)

						// this.cloneHead()
						$('.headTableBox').width($('.tableBox').width())
						this.tableHeadLocation()
						this.WarrantNameFixation()
						this.monitorShift()
					}
				},
				error: function (XMLHttpRequest) { }
			})
		},
		// 时间转换
		TurnTime (Data) {
			const Tiem = new Date(new Date(Data).getTime() - 1 * 60 * 60 * 1000)
			let Y, M, D, H, Min, MMM
			MMM = [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec'
			]
			Y = Tiem.getFullYear().toString().slice(-2)
			M = Tiem.getMonth()
			if (Tiem.getDate() < 10) {
				D = '0' + Tiem.getDate()
			} else {
				D = Tiem.getDate()
			}
			H = Tiem.getHours()
			if (Tiem.getMinutes() < 10) {
				Min = '0' + Tiem.getMinutes()
			} else {
				Min = Tiem.getMinutes()
			}
			return `${D} ${MMM[M]} ${Y} ${H}:${Min}`
		},
		// 搜索数据
		Submit: function () {
			if (this.underlyingData.length == 0) {
				this.underlyingData = ['all']
			}
			this.GetScreenerData()
		},
		// 重置
		info: function () {
			let timer1
			if (this.underlyingData.length == 0) {
				this.underlyingData = ['all']
			}
			this.GetScreenerData()
			$('#info').attr('disabled', true)
			clearTimeout(timer1)
			// 设置一次性定时器
			timer1 = setTimeout(function () {
				$('#info').attr('disabled', false)
			}, 5000)
			this.handleMouse(false)
		},
		// 重置提示
		handleMouse: function (ifov) {
			this.mouse = ifov
		},
		// 下拉菜单显示
		ComboBoxShow: function (ifv) {
			switch (ifv) {
				case 1:
					let Tarr = []
					let Farr = []
					let len
					this.ifUnderlying = !this.ifUnderlying
					this.ifMaybank = false
					this.iftableHeadShow = false
					Tarr = this.underlyingSeekData.filter(tiem => {
						return tiem.visible == true
					})
					Farr = this.underlyingSeekData.filter(tiem => {
						return tiem.visible == false
					})
					if (this.underlyingSeekData[0].show) {
						setTimeout(function () {
							$('.WarrantSearchHunt0').find('li').find('.input1').removeAttr('disabled')
							$('.WarrantSearchHunt0').find('li').find('.input2').removeAttr('disabled')
						}, 10)
					} else {
						setTimeout(function () {
							$('.WarrantSearchHunt0').find('li').find('.input1').attr('disabled', 'disabled')
							$('.WarrantSearchHunt0').find('li').find('.input2').attr('disabled', 'disabled')
						}, 10)
					}
					len = this.underlyingData.filter(tiem => tiem !== '')
					if (len.length == this.underlyingSeekData.length - 5) {
						this.selectData[0].data = [...Farr, ...Tarr]
						setTimeout(function () {
							$('.WarrantSearchHunt0').find('li').find('.input4').attr('disabled', 'disabled')
						}, 10)
					} else {
						this.selectData[0].data = [...Tarr, ...Farr]
						setTimeout(function () {
							$('.WarrantSearchHunt0').find('li').find('.input4').removeAttr('disabled')
						}, 10)
					}
					break
				case 2:
					this.ifMaybank = !this.ifMaybank
					this.ifUnderlying = false
					this.iftableHeadShow = false
					break
				default:
					this.iftableHeadShow = !this.iftableHeadShow
					this.ifMaybank = false
					this.ifUnderlying = false
					break
			}
		},
		// 下拉菜单消失
		ComboBoxHide: function (e) {
			this.ifUnderlying = false
			this.ifMaybank = false
			this.iftableHeadShow = false
		},
		// underlying下拉菜单的方法
		underlyingFun: function (data, e) {
			let len
			if (data.id == 0) {
				this.underlyingData = []
				this.selectData[0].data[0].show = false
				this.selectData[0].data[0].visible = false
				this.selectData[0].data[1].show = true
				this.selectData[0].data[2].show = true
				this.selectData[0].data[2].visible = true
				this.underlyingInputText = this.$t('WSe.AU')
				this.logUnderlyingInputText = this.$t('WSe.AU')
				setTimeout(function () {
					$('.WarrantSearchHunt0').find('li').find('.input2').attr('disabled', 'disabled')
				}, 10)
				$.each(this.selectData[0].data, (i, c) => {
					c.visible = true
					if (c.id == 0 || c.id == 1) {
						this.underlyingSeekData[i].visible = false
					} else {
						this.underlyingSeekData[i].visible = true
					}
					if (i >= 3) {
						this.underlyingData[i] = c.value
					}
				})
			}
			if (data.id == 1) {
				this.selectData[0].data[0].show = true
				this.selectData[0].data[1].show = false
				this.selectData[0].data[1].visible = false
				this.selectData[0].data[2].show = false
				this.selectData[0].data[2].visible = false
				this.underlyingData = []
				this.underlyingInputText = ''
				this.logUnderlyingInputText = ''
				$.each(this.selectData[0].data, (i, c) => {
					c.visible = false
					this.underlyingSeekData[i].visible = false
				})
			}
			if (data.id == 2) {
				this.selectData[0].data[1].visible = false
				this.selectData[0].data[2].visible = true
				this.underlyingInputText = this.$t('WSe.AU')
				this.logUnderlyingInputText = this.$t('WSe.AU')
				$.each(this.selectData[0].data, (i, c) => {
					c.visible = true
					if (c.id == 0 || c.id == 1) {
						this.underlyingSeekData[i].visible = false
					} else {
						this.underlyingSeekData[i].visible = true
					}
					if (i >= 3) {
						this.underlyingData[i] = c.value
					}
				})
			}
			if (data.id >= 3) {
				if (this.underlyingData.length == 1) {
					this.underlyingData = []
				}
				this.underlyingSeekData[1].visible = false
				data.visible = e.target.checked
				this.underlyingSeekData[this.underlyingSeekData.indexOf(data)].visible = e.target.checked
				if (e.target.checked) {
					this.underlyingData[data.id] = data.value
				} else {
					this.underlyingData[data.id] = ''
				}
				len = this.underlyingData.filter(tiem => tiem !== '')

				if (!len.length) {
					this.underlyingSeekData[0].show = true
					this.underlyingSeekData[0].visible = false
					this.underlyingSeekData[1].show = false
					this.underlyingSeekData[2].show = false
				} else {
					this.underlyingSeekData[0].show = false
					this.underlyingSeekData[0].visible = false
					this.underlyingSeekData[1].show = true
					this.underlyingSeekData[2].show = true
					if (len.length !== this.underlyingSeekData.length - 3) {
						if (this.selectData[0].data[2].name == this.$t('WSe.AU')) {
							this.selectData[0].data[2].visible = false
						}
						this.underlyingSeekData[2].visible = false
						setTimeout(function () {
							$('.WarrantSearchHunt0').find('li').find('.input2').removeAttr('disabled')
						}, 10)
					} else {
						if (this.selectData[0].data[2].name == this.$t('WSe.AU')) {
							this.selectData[0].data[2].visible = true
						}
						this.underlyingSeekData[2].visible = true
						setTimeout(function () {
							$('.WarrantSearchHunt0').find('li').find('.input2').attr('disabled', 'disabled')
						}, 10)
					}
				}
				this.underlyingInputText = len.toString()
				this.logUnderlyingInputText = len.toString()
			}
			this.GetScreenerData()
		},
		// underlying下拉菜单搜索
		underlyingSeek: function (e) {
			let Tarr = []
			let Farr = []
			this.underlyingInputText = e.target.value
			if (e.target.value.length == 0) {
				this.selectData[0].data = this.underlyingSeekData
			} else {
				this.selectData[0].data = this.$$.filtration(this.underlyingSeekData, e.target.value)
			}
			Tarr = this.selectData[0].data.filter(tiem => {
				return tiem.visible == true
			})
			Farr = this.selectData[0].data.filter(tiem => {
				return tiem.visible == false
			})
			this.selectData[0].data = [...Tarr, ...Farr]
			this.ifUnderlying = true
		},
		// issuer下拉菜单的方法
		issuerFun: function (data, e) {
			const _this = this
			let islen
			if (data.id == 0) {
				this.issuerData = []
				if (e.target.checked) {
					this.ssuerInputText = this.$t('WSe.AI')
				} else {
					this.ssuerInputText = ''
				}
				$.each(this.selectData[5].data, function (i, c) {
					if (i >= 1) {
						if (e.target.checked) {
							_this.issuerData.push(c.value)
						} else {
							_this.issuerData = []
						}
					}
					c.visible = e.target.checked
				})
			}

			if (data.id > 0) {
				data.visible = e.target.checked
				if (e.target.checked) {
					this.issuerData[data.id - 1] = data.value
				} else {
					this.issuerData[data.id - 1] = ''
				}
			}
			islen = this.issuerData.filter(tiem => tiem !== '')
			$.each(islen, (i, c) => {
				if (c == 'MSI') {
					islen[i] = 'MAYBANK'
				}
			})
			if (islen.length == this.selectData[5].data.length - 1) {
				this.selectData[5].data[0].visible = true
				this.ssuerInputText = this.$t('WSe.AI')
				this.logSsuerInputText = this.$t('WSe.AI')
			} else {
				this.selectData[5].data[0].visible = false
				this.ssuerInputText = islen.toString().toUpperCase()
				this.logSsuerInputText = islen.toString().toUpperCase()
			}
			this.GetScreenerData()
		},
		// 点击选框来显示数据
		doneTask: function (index, e) {
			this.tableHeadData[index].visible = e.target.checked
			if (e.target.checked) {
				if (index <= 8) {
					this.WARRANTLength++
					this.iosWARRANT = true
					if (index == 0 && this.scrollBarScrollLeft > 0) {
						$('.nameFixationTableBox').css({
							display: 'flex'
						})
						this.WarrantNameFixation()
					}
				} else if (index > 8 && index <= 13) {
					this.MARKETLength++
					this.iosMARKET = true
				} else if (index > 13) {
					this.INDICATORSLength++
					this.iosINDICATORSF = true
				}
				this.dataLiNum++
			} else {
				if (index <= 8) {
					this.WARRANTLength--
					if (this.WARRANTLength == 0) {
						this.iosWARRANT = false
					}
					if (index == 0 && this.scrollBarScrollLeft > 0) {
						$('.nameFixationTableBox').hide()
					}
				} else if (index > 8 && index <= 13) {
					this.MARKETLength--
					if (this.MARKETLength == 0) {
						this.iosMARKET = false
					}
				} else if (index > 13) {
					this.INDICATORSLength--
					if (this.INDICATORSLength == 0) {
						this.iosINDICATORSF = false
					}
				}
				this.dataLiNum--
			}
			if (this.dataLiNum === this.tableHeadData.length) {
				this.allData = true
			} else {
				this.allData = false
			}
			this.$$.tableWidth($('#WarrantSearch').find('.scrollBar').find('div'), $('#WarrantSearch').find('.tableBox').find('.table'), true)
			this.WarrantNameFixation()
		},
		// 是否全选显示数据
		allIfData: function (e) {
			if (e.target.checked) {
				this.allData = e.target.checked
				this.dataLiNum = this.tableHeadData.length
				this.WARRANTLength = 9
				this.MARKETLength = 5
				this.INDICATORSLength = 7
				this.iosWARRANT = true
				this.iosMARKET = true
				this.iosINDICATORSF = true
			} else {
				this.allData = e.target.checked
				this.dataLiNum = 0
				this.WARRANTLength = 0
				this.MARKETLength = 0
				this.INDICATORSLength = 0
				this.iosWARRANT = false
				this.iosMARKET = false
				this.iosINDICATORSF = false
			}
			this.$$.tableWidth($('#WarrantSearch').find('.scrollBar').find('div'), $('#WarrantSearch').find('.tableBox').find('.table'), true)
			this.WarrantNameFixation()
			$.each(this.tableHeadData, function (i, c) {
				c.visible = e.target.checked
			})
		},
		// 是否恢复原样
		ifStood: function (e) {
			if ($(window).width() <= 992) {
				if (e.target.checked) {
					this.WARRANTLength = 3
					this.MARKETLength = 3
					this.INDICATORSLength = 0
					this.iosINDICATORSF = false
					$.each(this.tableHeadData, (i, c) => {
						if (i !== 0 && i !== 4 && i !== 6 && i !== 9 && i !== 10 && i !== 11) {
							c.visible = false
						}
					})
					this.dataLiNum = 14
					this.allData = false
					this.iosWARRANT = true
					this.iosMARKET = true
					this.forbid = e.target.checked
				} else {
					this.forbid = e.target.checked
				}
			} else {
				if (e.target.checked) {
					$.each(this.tableHeadData, function (i, c) {
						if (i !== 3 && i !== 17 && i !== 18 && i !== 19) {
							c.visible = true
						} else {
							c.visible = false
						}
					})
					this.WARRANTLength = 8
					this.MARKETLength = 5
					this.INDICATORSLength = 4
					this.dataLiNum = 14
					this.allData = false
					this.iosWARRANT = true
					this.iosMARKET = true
					this.iosINDICATORSF = true
					this.forbid = e.target.checked
				} else {
					this.forbid = e.target.checked
				}
			}
			this.$$.tableWidth($('#WarrantSearch').find('.scrollBar').find('div'), $('#WarrantSearch').find('.tableBox').find('.table'), true)
			this.WarrantNameFixation()
		},
		// 排序
		ordering: function (sortBy, sortOrder) {
			this.sortBy = sortBy
			this.sortOrder = sortOrder
			if (this.underlyingData.length == 0) {
				this.underlyingData = ['all']
			}
			this.GetScreenerData()
		},
		// 清空输入框
		emptyInput: function (val, logVal, v) {
			this[val] = ''
		},
		// 回复输入框
		recoverInput: function (val, logVal) {
			this[val] = logVal
		},
		/* 打印table */
		correlationPrintTbale: function () {
			$('#printBox').show()
			$('.mainContent').find('.first').find('.performance').find('h3').hide()
			$('#printBox>.header').append($('#WarrantSearch').find('.headerpart').clone())
			$('#printBox>.mainContent>.scope').append($('#WarrantSearch').find('.mainContent>.scope>.first').clone())
			$('#printBox>.mainContent>.scope').append($('#WarrantSearch').find('.mainContent>.scope>.condition').clone())
			// $('#printBox>.content').find('.functionality').attr("style", "margin-top: 50px;")
			$('#printBox>.content').append($('#WarrantSearch').find('.tableBox').clone())
			$('#printBox>.bottom').append($('#WarrantSearch').find('.bottom-disclaimer').clone())
			$('#contentBox').hide()

			for (let i = 0, max = this.selectData; i < max.length; i++) {
				this.ariseCombobox(i, this.selectData[i].data[0].name)
			}

			const arr = []
			window.scrollTo(0, 0)
			disableScroll.on()

			html2canvas(this.$refs.printheader, {
				backgroundColor: '#ffffff',
				useCORS: true,
				height: this.$refs.printheader.scrollHeight,
				width: this.$refs.printheader.scrollWidth
			}).then((canvas) => {
				arr[0] = canvas.toDataURL('image/png')

				html2canvas(this.$refs.printmainContent, {
					backgroundColor: '#ffffff',
					useCORS: true,
					height: this.$refs.printmainContent.scrollHeight,
					width: this.$refs.printmainContent.scrollWidth
				}).then((canvas) => {
					arr[1] = canvas.toDataURL('image/png')
					html2canvas(this.$refs.printcontent, {
						backgroundColor: '#ffffff',
						useCORS: true,
						height: this.$refs.printcontent.scrollHeight,
						width: this.$refs.printcontent.scrollWidth
					}).then((canvas) => {
						arr[2] = canvas.toDataURL('image/png')

						html2canvas(this.$refs.printbottom, {
							backgroundColor: '#ffffff',
							useCORS: true,
							height: this.$refs.printbottom.scrollHeight,
							width: this.$refs.printbottom.scrollWidth
						}).then((canvas) => {
							arr[3] = canvas.toDataURL('image/png')

							var printstr = ''
							printstr += '<img width="100%" src="' + arr[0] + '"/>'
							printstr += '<img width="100%" src="' + arr[1] + '"/>'
							printstr += '<img width="100%" src="' + arr[2] + '"/>'
							printstr += '<img width="100%" src="' + arr[3] + '"/>'
							printJS({
								printable: printstr,
								type: 'raw-html',
								onLoadingStart: function () {
									$('#contentBox').show()
									$('#printBox').hide()
									$('#printBox').find('.content').empty()
									$('#printBox').find('.header').empty()
									$('#printBox').find('.mainContent>.scope').empty()
									$('#printBox').find('.bottom').empty()
									disableScroll.off()
								}
							})
						})
					})
				})
			})
		},
		/* 下载table */
		printTable: function () {
			let therdata = ''
			$.each($('.printTableBox>table>thead>tr').eq(1).find('th'), function (i, c) {
				if (i !== 1) {
					therdata += $('.printTableBox>table>thead>tr')
						.eq(1)
						.find('th')
						.eq(i)
						.text()
						.trim()
					if (
						i + 1 !=
						$('.printTableBox>table>thead>tr')
							.eq(1)
							.find('th').length
					) {
						therdata += ','
					}
				}
			})
			this.export_table_ws('.printTableBox', therdata, this.$$.export_name('warrant_search'))
		},
		export_table_ws: function (selector, otherdata, name) {
			if (document.querySelectorAll(selector).length == 0) {
				alert('Selector "' + selector + '" not exists!')
				return false
			}
			this.$$.export_csv(
				otherdata + '\n' + this.get_table_data_ws(selector),
				name
			)
		},
		get_table_data_ws: function (selector) {
			let data = ''
			$.each($(selector + '>table>tbody').find('tr'), function (i, c) {
				$.each($(selector + '>table>tbody').find('tr').eq(i).find('td'), function (j, t) {
					if (j !== 1) {
						if (j == 5) {
							data += $(selector + '>table>tbody').find('tr').eq(i).find('td').eq(j).text().replace(/\s*/g, '').trim().split(',').join('')
						} else {
							data += $(selector + '>table>tbody').find('tr').eq(i).find('td').eq(j).text().trim().split(',').join('')
						}

						if (j + 1 != $(selector + '>table>tbody').find('tr').eq(i).find('td').length) {
							data += ','
						}
					}
				})
				data += '\n'
			})
			return data
		},
		// table 头部定位
		tableHeadLocation: function () {
			const _this = this
			let width2
			$('.nameFixationTableBox').width($('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(0).outerHeight(true))
			$('.NameTableBox').width($('.nameFixationTableBox').width())
			$(window).scroll(function () {
				if ($(this).scrollTop() > ($('#WarrantSearch').find('.tableBox').offset().top + 119)) {
					if ($(this).scrollTop() < $('#WarrantSearch').find('.bottom-disclaimer').offset().top - 200) {
						$('.headTableBox').css({
							display: 'flex'
						})
						$('.NameTableBox').css({
							display: 'flex'
						})
					} else {
						$('.headTableBox').hide()
						$('.NameTableBox').hide()
					}
				} else {
					$('.headTableBox').hide()
					$('.NameTableBox').hide()
				}
			})
		},
		// 监听屏幕
		MonitorScreen: function () {
			if ($(window).width() <= 992) {
				this.WARRANTLength = 3
				this.MARKETLength = 3
				this.INDICATORSLength = 0
				this.iosINDICATORSF = false
				this.allData = false
				$.each(this.tableHeadData, (i, c) => {
					if (i !== 0 && i !== 4 && i !== 6 && i !== 9 && i !== 10 && i !== 11) {
						c.visible = false
					}
				})
				this.nameFixationTableBoxT = false
			} else {
				this.WARRANTLength = 8
				this.MARKETLength = 5
				this.INDICATORSLength = 4
				this.iosINDICATORSF = true
				this.dataLiNum = 14
				this.allData = false
				$.each(this.tableHeadData, (i, c) => {
					if (i !== 3 && i !== 17 && i !== 18 && i !== 19) {
						c.visible = true
					} else {
						c.visible = false
					}
				})
				this.nameFixationTableBoxT = true
			}

			this.$$.tableWidth($('#WarrantSearch').find('.scrollBar').find('div'), $('#WarrantSearch').find('.tableBox').find('.table'), true)
			const _this = this
			if (!this.$$._isMobile()) {
				$(window).resize(() => {
					$('.headTableBox').width($('.tableBox').width())
					if ($(window).width() <= 992) {
						this.WARRANTLength = 3
						this.MARKETLength = 3
						this.INDICATORSLength = 0
						this.iosINDICATORSF = false
						this.allData = false
						$.each(this.tableHeadData, (i, c) => {
							if (i !== 0 && i !== 4 && i !== 6 && i !== 9 && i !== 10 && i !== 11) {
								c.visible = false
							}
						})
						_this.nameFixationTableBoxT = false
					} else {
						this.WARRANTLength = 8
						this.MARKETLength = 5
						this.INDICATORSLength = 4
						this.iosINDICATORSF = true
						this.dataLiNum = 14
						this.allData = false
						$.each(this.tableHeadData, (i, c) => {
							if (i !== 3 && i !== 17 && i !== 18 && i !== 19) {
								c.visible = true
							} else {
								c.visible = false
							}
						})
						_this.nameFixationTableBoxT = true
					}
					this.$$.tableWidth($('#WarrantSearch').find('.scrollBar').find('div'), $('#WarrantSearch').find('.tableBox').find('.table'), true)
				})
			}
		},
		//  Warrant Name 固定
		WarrantNameFixation: function () {
			const _this = this
			setTimeout(function () {
				$('.nameFixationTableBox').width($('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(0).outerWidth(true))
			}, 10)
			$('.NameTableBox').width($('.nameFixationTableBox').width())
			$('#WarrantSearch').find('.tableBox').scroll(function () {
				_this.scrollBarScrollLeft = $(this).scrollLeft()

				$('.headTableBox').scrollLeft($(this).scrollLeft())
				let topNum
				if (_this.nameFixationTableBoxT) {
					topNum = 25
				} else {
					topNum = 5
				}
				$('.nameFixationTableBox').css({
					top: $('#WarrantSearch').find('.mainContent').find('.scope').find('.first').outerHeight(true) + $('#WarrantSearch').find('.mainContent').find('.scope').find('.condition').outerHeight(true) + topNum
				})
				if ($(this).scrollLeft() > 0 && _this.tableHeadData[0].visible) {
					$('.nameFixationTableBox').css({
						display: 'flex'
					})
				} else {
					$('.nameFixationTableBox').hide()
				}
				$('.nameFixationTableBox').width($('#WarrantSearch').find('.tableBox').find('.table').find('thead').find('tr').eq(1).find('th').eq(0).outerWidth(true))
				$('.NameTableBox').width($('.nameFixationTableBox').width())
			})
		},
		monitorShift: function () {
			let scopeH = 0
			let windowT = $(window).scrollTop()
			let windowW = $(window).width()
			let scopeW = $('#WarrantSearch').find('.mainContent').find('.scope').width()
			let table1 = 0
			let table2 = 0
			let table3 = 0
			let H = 0
			setTimeout(function () {
				if (windowW > 768) {
					scopeH = $('#WarrantSearch').find('.mainContent').find('.scope').height() - 300
					H = 300
				} else {
					scopeH = $('#WarrantSearch').find('.mainContent').find('.scope').height() + 100
					H = 600
				}
				if ($('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.table1').length) {
					table1 = $('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.table1').width()
				}
				if ($('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.table2').length) {
					table2 = $('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.table2').width()
				}
				if ($('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.table3').length) {
					table3 = $('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.table3').width()
				}
				if (windowT <= H || windowT > scopeH) {
					$('#WarrantSearch').find('.mainContent').find('.shift').hide()
				}
				if (windowT > H) {
					if (scopeW > (table1 + table2 + table3)) {
						$('#WarrantSearch').find('.mainContent').find('.shift').hide()
					} else {
						$('#WarrantSearch').find('.mainContent').find('.shift').show()
					}
				}
			}, 10)

			$(window).resize(() => {
				windowW = $(window).width()
				if (windowW > 768) {
					scopeH = $('#WarrantSearch').find('.mainContent').find('.scope').height() - 300
					H = 300
				} else {
					scopeH = $('#WarrantSearch').find('.mainContent').find('.scope').height() + 100
					H = 600
				}
				scopeW = $('#WarrantSearch').find('.mainContent').find('.scope').width()
				if ($('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.col').eq(0).length) {
					table1 = $('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.col').eq(0).width()
				}
				if ($('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.col').eq(1).length) {
					table2 = $('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.col').eq(1).width()
				}
				if ($('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.col').eq(2).length) {
					table3 = $('#WarrantSearch').find('.mainContent').find('.scope').find('.tableBox').find('.col').eq(2).width()
				}
				if (windowT > H) {
					if (scopeW > (table1 + table2 + table3)) {
						$('#WarrantSearch').find('.mainContent').find('.shift').hide()
					} else {
						$('#WarrantSearch').find('.mainContent').find('.shift').show()
					}
				}
			})
			$(window).scroll(function () {
				windowT = $(this).scrollTop()
				if (windowT > H) {
					if (scopeW > (table1 + table2 + table3)) {
						$('#WarrantSearch').find('.mainContent').find('.shift').hide()
					} else {
						$('#WarrantSearch').find('.mainContent').find('.shift').show()
					}
				}
				if (windowT <= H || windowT > scopeH) {
					$('#WarrantSearch').find('.mainContent').find('.shift').hide()
				}
			})
		},
		// 点击移动列表
		clickShiftRight: function () {
			const scrollRight = $('.tableBox').scrollLeft()
			$('.tableBox').scrollLeft(scrollRight + 10)
		},
		clickShiftLeft: function () {
			const scrollRight = $('.tableBox').scrollLeft()
			$('.tableBox').scrollLeft(scrollRight - 10)
		},
		// 出现combobox
		ariseCombobox: function (index, name) {
			const _this = this
			this.$$.jqcombobox('#comboboxSelect' + index)
			$('#comboboxSelect' + index).combobox({
				select: function (event, ui) {
					switch (index) {
						case 1:
							_this.typeD = this.value
							break
						case 2:
							_this.moneynessD = this.value
							break
						case 3:
							_this.maturityD = this.value
							break
						case 4:
							_this.effectiveGearingD = this.value
							break
						case 6:
							_this.expiryD = this.value
							break
						case 7:
							_this.indicatorD = this.value
							break
						case 8:
							_this.sensitivity = this.value
							break
					}
					$('#comboboxSelect' + index).parent().find('input').val(ui.item.innerText)
					if (index !== 5 || index !== 0) {
						_this.$$.inputEmpty('.WarrantSearchHunt' + index)
					}
				}
			})

			if (index !== 5 || index !== 0) {
				this.$$.inputEmpty('.WarrantSearchHunt' + index)
			}
		}
	}
}
